import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSimpleReducer from "../utils/createSimpleReducer";
import authUserReducer from "./authUserReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authUser", "announcement"],
};

const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    authUser: authUserReducer,
    shallowUsers: createSimpleReducer("shallowUsers"),
    latestWinningNumberSummaries: createSimpleReducer(
      "latestWinningNumberSummaries"
    ),
    pools: createSimpleReducer("pools"),
    userBanks: createSimpleReducer("userBanks"),
    websiteSettings: createSimpleReducer("websiteSettings"),
    transactions: createSimpleReducer("transactions"),
    promos: createSimpleReducer("promos"),
    memos: createSimpleReducer("memos"),
    referralUsers: createSimpleReducer("referralUsers"),
    referralPeriods: createSimpleReducer("referralPeriods"),
    banks: createSimpleReducer("banks"),
    periods: createSimpleReducer("periods"),
    gameInfos: createSimpleReducer("gameInfos"),
    deposits: createSimpleReducer("deposits"),
    withdraws: createSimpleReducer("withdraws"),
    bets: createSimpleReducer("bets"),
    pragmaGameItems: createSimpleReducer("pragmaGameItems"),
    thirdPartyGames: createSimpleReducer("thirdPartyGames"),
    spinnerPrizes: createSimpleReducer("spinnerPrizes"),
    totalPages: createSimpleReducer("totalPages"),
    urls: createSimpleReducer("urls"),
  })
);

export default rootReducer;
