import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";

function AppRoutes(props) {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href="https://d155v07pdno3ek.cloudfront.net/22.webp"
          type="image/webp"
        />
        <meta charset="utf-8" />
        <title>Situs Duogaming Slot Online Paling Gacor Di Indonesia</title>
        <link rel="canonical" href="https://stillwatercurrent.com/" />
        <meta name="viewport" content="width=device-width" />
        <meta
          name="description"
          content="Duogaming adalah salah satu daftar game situs slot online paling gacor resmi terbaik di indonesia 2023 yang memberikan rumus RTP maxwin terpercaya."
        />
        <meta
          name="keywords"
          content="situs slot gacor, situs slot indonesia, slot online resmi, game slot terbaik, daftar slot terpercaya, bonus slot duogaming, rtp slot gacor, slot kamboja, slot thailand, agen slot 2023, situs slot terbaru, slot maxwin, live rtp slot, slot habanero, slot microgaming, slot Pg soft, slot toptrend, idnslot gacor"
        />
        <meta name="robots" content="index, follow" />
        <meta name="rating" content="general" />
        <meta name="geo.region" content="id_ID" />
        <meta name="googlebot" content="index,follow" />
        <meta name="geo.country" content="ID" />
        <meta name="language" content="Id-ID" />
        <meta name="distribution" content="global" />
        <meta name="geo.placename" content="Indonesia" />
        <meta name="author" content="Situs slot gacor" />
        <meta name="publisher" content="Situs slot gacor" />
        <meta
          name="google-site-verification"
          content="vuJ0-YwUe_6xTbT2BadIMetf6WEoEtO77OjzWkHkPbc"
        />
        <meta name="page-locale" content="id,en" />
        <meta content="true" name="HandheldFriendly" />
        <meta content="width" name="MobileOptimized" />
        <meta content="indonesian" name="language" />
        <meta name="og:type" content="website" />
        <meta name="og:locale" content="id_ID" />
        <meta property="og:locale:alternate" content="en_US" />
        <meta
          name="og:title"
          content="Situs Duogaming Slot Online Paling Gacor Di Indonesia"
        />
        <meta
          name="og:description"
          content="Duogaming adalah salah satu daftar game situs slot online paling gacor resmi terbaik di indonesia 2023 yang memberikan rumus RTP maxwin terpercaya."
        />
        <meta name="og:url" content="https://www.stillwatercurrent.com/" />
        <meta name="og:site_name" content="Situs slot gacor" />
        <meta property="og:image:alt" content="Situs slot gacor" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Situs Duogaming Slot Online Paling Gacor Di Indonesia"
        />
        <meta
          name="twitter:description"
          content="Situs Duogaming Slot Online Paling Gacor Di Indonesia"
        />

        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="466" />
        <meta
          name="categories"
          content="slot gacor, judi online, slot online, judi slot online"
        />
        <meta name="copyright" content="Duogaming" />
        <meta name="tgn.nation" content="Indonesia" />
        <meta name="Googlebot-Image" content="follow, all" />
        <meta name="Scooter" content="follow, all" />
        <meta name="msnbot" content="follow, all" />
        <meta name="alexabot" content="follow, all" />
        <meta name="Slurp" content="follow, all" />
        <meta name="ZyBorg" content="follow, all" />
        <meta name="yahoobot" content="follow, all" />
        <meta name="bingbot" content="follow, all" />
        <meta name="MSSmartTagsPreventParsing" content="true" />
        <meta name="audience" content="all" />
        <meta name="geo.position" content="-6.283818;106.804863" />
        <meta name="ICBM" content="-6.283818, 106.804863" />
      </Helmet>
      <Switch>
        <Route exact path="/" component={HomePage} />
      </Switch>
    </>
  );
}

export default AppRoutes;
