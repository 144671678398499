import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { setLocale } from "yup";
import "./App.css";
import AppRoutes from "./AppRoutes";
import store, { persistor } from "./store/store";

// if (
//   process.env.REACT_APP_ENV !== "test" &&
//   process.env.REACT_APP_ENV !== "dev"
// ) {
//   ReactGA.initialize("UA-200604834-1");
//   ReactGA.pageview(window.location.pathname + window.location.search);
// }

setLocale({
  mixed: {
    required: "Tidak boleh kosong.",
  },
});

function App() {
  return (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRoutes />
        </PersistGate>
    </Provider>
  );
}

export default App;
